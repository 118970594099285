<template>
  <div class="w-100 text-center mt-4">
    <router-link class="" :to="{ name: 'Home'}">
      <img alt="Logo" height="60" src="@/assets/logo-1.png">
    </router-link>
  </div>
  <div id="login" class="card mt-3">
    <div class="card-body">
      <template v-if="view=='login'">
        <div v-if="error" class="alert alert-danger">Email or password incorrect</div>
        <form @submit.prevent="submit()">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="email">Email</label>
                <input type="text" class="form-control" id="email" v-model.trim="v$.email.$model">
                <div v-if="v$.email.$dirty">
                  <small class="form-text text-danger" v-if="v$.email.required.$invalid">Required field</small>
                  <small class="form-text text-danger" v-if="v$.email.email.$invalid">Format incorrect</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password" v-model.trim="v$.password.$model">
                <div v-if="v$.password.$dirty">
                  <small class="form-text text-danger" v-if="v$.password.required.$invalid">Required field</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <router-link class="" :to="{ name: 'ForgotPassword' }"> Forgot Password </router-link>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button class="btn btn-primary" v-bind:class="{ disabled: v$.$invalid }" :disabled="v$.$invalid">
                <span v-if="!loading">LOG IN</span>
                <img v-if="loading" src="@/assets/loader.svg" height="20" alt=""></button>
            </div>
          </div>
        </form>
        <div class="row d-flex justify-content-center border-top mt-4 pt-3">
          <router-link class="" :to="{ name: 'SignUp' }"> Sign Up </router-link>
        </div>
      </template>
      <template v-if="view=='NEW_PASSWORD_REQUIRED'">
        <form @submit.prevent="renewPassword()">
          <div class="row">
            <div class="col-12">
              <h2 class="h4">Set your password</h2>
              <div class="form-group">
                <label for="new_password">New Password</label>
                <input type="password" class="form-control" id="new_password" v-model.trim="v$.new_password.$model">
                <div v-if="v$.new_password.$dirty">
                  <small class="form-text text-danger" v-if="v$.new_password.awsRegex.$invalid">Format incorrect</small>
                  <small class="form-text text-danger" v-if="v$.new_password.required.$invalid">Required field</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="rnew_password">Repeat Password</label>
                <input type="password" class="form-control" id="rnew_password" v-model.trim="v$.rnew_password.$model">
                <div v-if="v$.rnew_password.$dirty">
                  <small class="form-text text-danger" v-if="v$.rnew_password.awsRegex.$invalid">Format incorrect</small>
                  <small class="form-text text-danger" v-if="v$.rnew_password.sameAs.$invalid">Passwords do not match</small>
                  <small class="form-text text-danger" v-if="v$.rnew_password.required.$invalid">Required field</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button class="btn btn-primary" v-bind:class="{ disabled: v$.$invalid }" :disabled="v$.$invalid">
                <span v-if="!loading">Continue</span>
                <img v-if="loading" src="@/assets/loader.svg" height="20" alt=""></button>
            </div>
          </div>
        </form>
      </template>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { required, email, requiredIf, helpers, sameAs  } from '@vuelidate/validators'

const awsRegex = helpers.regex(/^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/)

export default {
  name: 'Login',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      error: false,
      view: 'login',
      new_password: '',
      rnew_password: '',
      session: ''
    };
  },
  created() {
    if(this.$isLogged()){
      window.location.href = "/dashboard";
    }
  },
  mounted(){
    this.$emit('widgetLoaded');
  },
  validations () {
    return {
      email: {
        required,
        email
      },
      password: {
        required: requiredIf(function(){ return this.view === 'login' }),
      },
      new_password: {
        awsRegex,
        required: requiredIf(function(){ return this.view === 'NEW_PASSWORD_REQUIRED' }),
      },
      rnew_password: {
        awsRegex,
        sameAs: sameAs(this.new_password),
        required: requiredIf(function(){ return this.view === 'NEW_PASSWORD_REQUIRED' }),
      }
    }
  },
  methods: {

    submit() {
      const bodyToSend = {
        email: this.email,
        password: this.password,
      };
      this.loading = true;
      this.error = false;

      axios.post('/cryptos/auth', bodyToSend)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if(response.data.statusCode !== 200){
            this.error = true;
          }
          else if(response.data.body.response.ChallengeName === 'NEW_PASSWORD_REQUIRED' ){
              this.session = response.data.body.response.Session
              this.view = 'NEW_PASSWORD_REQUIRED';
          }
          else{
            this.view = 'login';
            this.loginOK(response.data.body.response.AuthenticationResult);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    renewPassword(){
      const bodyToSend = {
        email: this.email,
        newPassword: this.new_password,
        session: this.session,
        state: this.view
      };
      this.loading = true;
      this.error = false;

      axios.post('/cryptos/auth', bodyToSend)
        .then((response) => {
          this.loading = false;
          if(response.data.statusCode !== 200){
            this.error = true;
          }
          else{
            this.loginOK(response.data.body.response);
          }
        });
    },
    loginOK(data){
      let jwt = this.parseJwt(data.IdToken);
      this.$cookies.set('access_token', data.AccessToken,data.ExpiresIn);
      this.$cookies.set('session', data.IdToken,data.ExpiresIn);
      this.$cookies.set('refresh_token', data.RefreshToken,data.ExpiresIn);
      this.$cookies.set('idUser', jwt.sub,data.ExpiresIn);
      this.$cookies.set('email', jwt.email,data.ExpiresIn);
      window.location.href = "/dashboard";
    },
    parseJwt (token){
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
